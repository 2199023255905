import { Divider, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Content } from '../components/Content';
import Layout from '../components/Layout';
import { useTranslation } from '../contexts/LanguageContext';

export default function Sobre() {
  const { t, locale } = useTranslation();

  return (
    <Layout title={t('about')} bg="/site.jpg">
      <Content>
        <Heading>{t('aboutme')}</Heading>
        {locale === 'en' ? (
          <Text>
            I work with Design for over a decade. I focus the major part of my
            efforts in design and web development and I follow up closely with
            the evolution of the area's technologies and methods. I'm always
            willing to help, learn and share knowledge. I have a major in Design
            at Mackenzie University.
          </Text>
        ) : (
          <Text>
            Meu nome é Elves Sousa e trabalho com Design por mais de uma década.
            Foquei a maior parte dos meus esforços no webdesign e acompanhei de
            perto a evolução das tecnologias e métodos na área. Sou também um
            generalista, pronto a ajudar, aprender e compartilhar
            conhecimento.Sou formado em Desenho Industrial na Universidade
            Presbiteriana Mackenzie.
          </Text>
        )}

        <Link
          d="block"
          fontSize="1.2rem"
          fontWeight="black"
          href="https://elvessousa.com.br"
          isExternal
          mb="4rem"
          textAlign="center"
        >
          {t('portfolio')}
        </Link>

        <Divider variant="dashed" w="60vw" m="4rem auto" />

        <Heading>{t('aboutsite')}</Heading>
        {locale === 'en' ? (
          <Text>
            This blog was made using Gatsby and React for the static site
            generation and Sass for the styles. The photo editing was made in
            GIMP.
          </Text>
        ) : (
          <Text>
            Este blog foi desenvolvido utilizando a Gatsby e React para a
            criação do HTML e Sass para os estilos. As imagens foram editadas no
            GIMP.
          </Text>
        )}

        <Divider variant="dashed" w="60vw" m="4rem auto" />

        <Heading>{t('aboutcontent')}</Heading>
        {locale === 'en' ? (
          <Text className="disclaimer">
            Any opinions expressed on this blog do not necessarily represent the
            vision of my clients or companies for which I provide services.
          </Text>
        ) : (
          <Text className="disclaimer">
            Quaisquer opiniões expressas neste blog não representam
            necessariamente a visão de meus clientes ou empresas para as quais
            presto serviços.
          </Text>
        )}
      </Content>
    </Layout>
  );
}
